import Footer from "../../components/footer";
import Header from "../../components/header"
import DownloadAppWrapper from "../../components/homepage/downloadAppWrapper";
import FaqWrapper  from '../../components/faq/faqWrapper';
import { Testimonal } from '../../components/homepage/testimonal';
import { Transactions } from '../../components/homepage/transactions';
import { TopBanner } from '../../components/homepage/topBanner';
import { PaymentMethods } from '../../components/homepage/paymentMethods';
import { KeyAdvantages } from '../../components/homepage/keyAdvantages';
import { SupportBy } from '../../components/homepage/supportBy';
import {faqQues} from '../../components/faq/faqUtils'

const HomePage = (props) => {
    return (
    <div>
       
        <Header />
        <TopBanner />
        <KeyAdvantages />
        <PaymentMethods />
        <Transactions />
        <Testimonal />
        <FaqWrapper faqQues={faqQues} title='Frequently asked Questions' subTitle='Everything you need to know about Your Wallet Botswana App' />
        <DownloadAppWrapper />
        <Footer />
    </div>)
}

export default HomePage